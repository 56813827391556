<template>
  <div class="page page__projekte">
    <h1>coming soon</h1>
    <TheFooter class="section" />
  </div>
</template>

<script>
import TheFooter from "../components/common/footer/TheFooter.vue";
import { onBeforeUnmount, onMounted, onUpdated } from "@vue/runtime-core";

//gsap

import { useStore } from "vuex";

export default {
  components: {
    TheFooter,
  },

  setup() {
    const store = useStore();
    // MENU STYLINGS:

    onMounted(() => {});

    onBeforeUnmount(() => {});

    onUpdated(() => {});

    return { store };
  },
};
</script>
<style
  lang="
    scss"
></style>
